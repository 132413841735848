<template>
	<div>
		
		
		<div id="header">
			
			<div class="header-mobile mobile">
				<img :src="project.logo" />
				<div id="connect_wallet">
					<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')">Connect wallet</div>
					<div v-else>
						{{ $root.wallet_address.substring(0, 4) }} ... {{ $root.wallet_address.substring(40) }}<br/>
						Balance: {{ token_balance.toFixed(2) }} {{ project.token_name }}
					</div>
				</div>
			</div>
			<div style="width: 320px; text-align: left; padding: 17px;"><img :src="project.logo" class="desktop" /></div>
			<div id="connect_wallet"  class="desktop">
				<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')">Connect wallet</div>
				<div v-else>
					{{ $root.wallet_address.substring(0, 4) }} ... {{ $root.wallet_address.substring(40) }}<br/>
					Balance: {{ token_balance.toFixed(2) }} {{ project.token_name }}
				</div>
			</div>
			<div class="advertisement">
				<a href="https://effe2.xyz/" target="_blank" style="color: #fff; text-decoration: none;"> 
					Powered by Effe²<br/>
					<span>Free tools for NFT projects on Solana</span>
				</a>
			</div>
		</div>
		
		<template v-if="staked.length > 0">
			<div class="staking-page-title staking-page-title-staked">
				<span></span>
				<span>Staked</span>
				<span></span>
			</div>
			<div class="staking-page-subtitle">
				<span>Rate : {{ total_rate }} {{ project.token_name }} / day</span>
				<span>Earned {{ total_reward.toFixed(5) }} {{ project.token_name }}</span>
				<div>
					<div class="button button-primary button-mini" @click="claim_all()">Claim</div>
					<div class="button button-primary button-mini" @click="unstake_all()">Unstake All</div>
				</div>
			</div>
			<div id="raffles">
				<div class="raffle-container" v-for="(nft, id) in staked" :key="id">
					<template>
						<div class="raffle-background-container">
							<div class="raffle" :style="'background: url('+nft.image+') no-repeat; background-size: contain;'" v-if="nft.in_wallet === true"></div>
							<div class="raffle" :style="'background: url('+nft.image+') no-repeat; background-size: contain;filter: grayscale(100%);'" v-if="nft.in_wallet === false"></div>
						</div>
						<div class="raffle-description" >
							<div class="raffle-title">{{ nft.computed_reward.toFixed(5) }} {{ project.token_name }}</div>
							<div class="raffle-title">{{ nft.name }}</div>
							<div class="raffle-button button-primary" @click="unstake(nft)">Unstake</div>
						</div>
					</template>
				</div>
			</div>
		</template>
		
		<template v-if="not_staked.length > 0">
			<div class="staking-page-title staking-page-title-staked">
				<span></span>
				<span>Not staked</span>
				<span></span>
			</div>
			<div class="staking-page-subtitle">
				<span></span>
				<div class="button button-primary button-mini" @click="stake_all()">Stake All</div>
				<span></span>
			</div>
			<div id="raffles">
				<div class="raffle-container" v-for="(nft, id) in not_staked" :key="id">
					<template>
						<div class="raffle-background-container">
							<div class="raffle" :style="'background: url('+nft.image+') no-repeat; background-size: contain;'"></div>
						</div>
						<div class="raffle-description" >
							<div class="raffle-title">{{ nft.name }}</div>
							<div class="raffle-title">{{ nft.reward }} {{ project.token_name }} / day</div>
							<div class="raffle-button button-primary" @click="stake(nft)">Stake</div>
						</div>
					</template>
				</div>
			</div>
		</template>
		
		<template v-if="not_staked.length == 0 && staked.length == 0">
			<h2 style="text-align: center; margin-top: 100px;">No NFT found in your wallet for this project :/</h2>
		</template>
		
	</div>
</template>


<script>


import { fetch_all_staking_accounts, stake, stake_all,claim, claim_all, unstake, unstake_all} from '@/libs/rustProgram';
import {TweenMax, Quart} from 'gsap';
import {getWalletAddress, getSolanaObject} from '@/libs/wallet';
import {getProject} from '@/libs/project';
import $ from 'jquery';
import { establishConnection, getTokenAccountForTokenAndOwner, getTokenAccountBalance, confirmTransaction, getNftOwned, getNftMetadata } from "@/libs/solanaConnection";
import { PublicKey } from '@solana/web3.js';

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;'
	}
};

var connection = null;


export default {
	name: 'Staking',
	components: {},

	data: function () {

		return {
			
			compute_reward_running: false,
			nft_owned: [],
			collections: [],
			project: {},
			total_rate: 0,
			token_balance: 0,
			total_reward: 0,
			not_staked: [],
			staked: [],
			last_time: false,
		}
	},
	
	computed: {
		
		
	},

	created: function(){
		
		var $this = this;
		
		this.$root.$on('projectSelected', function() {
		
		
			$this.project = $this.$root.project;
		
			$this.get_collections();
		});
		
		this.$root.$on('walletConnected', function() {
			
			$this.get_token_balance();
			
			$this.get_staking_state();
			
		});
		
		this.$root.get_project_ready = true;
		
		this.$root.$emit('openPopup')
	},
	mounted: function(){
	
	},

	methods: {
	
		get_token_balance: async function() {
			
			if(!connection)
				connection = await establishConnection();
			
			var buyer_token_account = await getTokenAccountForTokenAndOwner(this.project.token_address, getWalletAddress());
			
			var balance = await getTokenAccountBalance(new PublicKey(buyer_token_account));
			
			this.token_balance = balance;
		},
	
		get_nfts_owned: async function() {
			
			var nfts = await getNftOwned(getWalletAddress());
			
			this.nft_owned = nfts;
			
			
		},
		
		get_collections: async function() {
			
			var component = this;
			var project = this.project;
			
			axios.get('https://raffle-back.effe2.xyz/staking/get-collections/'+project.id).then(function (result) {
				
				component.collections = result.data;
			});
		},
		
		get_staking_state: async function() {
			
			await this.get_nfts_owned();
			
			var staking_accounts = await fetch_all_staking_accounts(getSolanaObject(), getWalletAddress());
			
			this.staked = [];
			this.not_staked = [];
			var account, nft, hashlist, collection, escrow, i, i_nft;
			
			// nft in wallet
			for(i in this.collections) {
				
				hashlist = this.collections[i].hashlist;
				
				for(i_nft in hashlist) {
					
					var nft_address = hashlist[i_nft];
					
					if(this.nft_owned.indexOf(nft_address) >= 0) {
						
						nft = await this.get_nft_info(nft_address);
						
						nft.nft_address = nft_address;
						nft.escrow = this.collections[i].escrow;
						
						nft.reward = this.collections[i].reward;
						nft.collection_id = this.collections[i].id;
						
						var staked = false;
						
						// check if it's staked accounts
						for(account of staking_accounts) {
						
							if(account.account.mint.toString() == nft_address) {
							
								staked = true;
								
								nft.last_claim = account.account.lastClaim.toString();
								nft.reward = account.account.reward.toString();
								nft.computed_reward = 0;
								nft.escrow_staking = account.publicKey.toString();
								nft.in_wallet = true;
								
								this.staked.push(nft);
							}
						}
						
						if(!staked)
							this.not_staked.push(nft);
					}
				}
			}
			
			var found;
			
			// nft out of wallet
			for(account of staking_accounts) {
			
				for(i in this.collections) {
				
					hashlist = this.collections[i].hashlist;
					
					found = false;
						
					if(hashlist.indexOf(account.account.mint.toString()) >= 0)
						found = true;
					
				}
				
				if(found === false)
					continue;
					
				if(this.nft_owned.indexOf(account.account.mint.toString()) == -1) {
				
					// we have to add account.account.mint.toString() to staked nft
					nft = await this.get_nft_info(account.account.mint.toString());
					
					var collection_id = 0;
					escrow = '';
					
					for(collection of this.collections) {
					
						hashlist = collection.hashlist;
						
						if(hashlist.indexOf(account.account.mint.toString()) >= 0) {
						
							collection_id = collection.id;
							escrow = collection.escrow;
						}
					}
					
					
					if(account.account.stopedAt)
						nft.stoped_at = account.account.stopedAt.toString();
					else
						nft.stoped_at = 0;
						
					nft.last_claim = account.account.lastClaim.toString();
					nft.reward = account.account.reward.toString();
					nft.computed_reward = 0;
					nft.escrow = escrow;
					nft.escrow_staking = account.publicKey.toString();
					nft.nft_address = new PublicKey(account.account.mint.toString());
					nft.collection_id = collection_id;
					nft.in_wallet = false;
					
					this.staked.push(nft);
				}
			}
			
			if(!this.compute_reward_running)
				this.compute_pending_rewards();
				
			this.compute_reward_running = true;
		},
		
		get_block_time: async function() {
			
			if(!connection)
				connection = await establishConnection();
			
			let slot = await connection.getSlot();
			
			var timestamp_solana;
			
			try {

				timestamp_solana = await connection.getBlockTime(slot);
				
				// console.log('timestamp', timestamp_solana);
				return timestamp_solana;
				
			}
			catch(e) {
				
				// console.log('error while getting block time', e);
				
				return await this.get_block_time(true);
			}
			
			
		},
		
		compute_pending_rewards: async function() {
			
			var timestamp_solana;
			
			if(this.last_time === false) {
			
				timestamp_solana = await this.get_block_time();
				this.last_time = timestamp_solana;
			}
			else {
			
				this.last_time += 1;
				timestamp_solana = this.last_time;
			}
				
			this.total_reward = 0;
			this.total_rate = 0;
			
			var project = this.project;
			
			var power = Math.pow(10, project.decimals);
			
			var difference;
			
			for(var nft of this.staked) {
			
				
				if(nft.stoped_at > 0)
					difference = parseInt(nft.stoped_at) - parseInt(nft.last_claim);
				else
					difference = timestamp_solana - parseInt(nft.last_claim);
					
				if(nft.in_wallet === false) {
					
					console.log(nft.nft_address.toString());
					continue;
				}
					
				var reward_per_day = parseInt(nft.reward) / power;
				
				var reward = reward_per_day * difference / 86400;
				
				this.total_rate += reward_per_day;
				
				nft.computed_reward = reward;
				
				this.total_reward += nft.computed_reward;
			}
			
			var $this = this;
			
			setTimeout(function() { $this.compute_pending_rewards() }, 1000);
		},
		
		get_nft_info: async function(nft_address) {
			
			var metadata = await getNftMetadata(nft_address);
			
			var uri = metadata.data.uri.replaceAll('\u0000', '');
			
			var json = await axios.get(uri);
			
			return {
				
				name: json.data.name,
				image: json.data.image,
			};
		},
		
		stake: async function(nft) {
			
			var signature, confirmed_transaction;
			
			var project = this.project;
			
			this.$root.$emit('openLoader');
			
			try {
				
				signature = await stake(getSolanaObject(), getWalletAddress(), nft.escrow, nft.nft_address, project.token_address, nft.collection_id, project.id);
				
				confirmed_transaction = await confirmTransaction(signature);
				
				if(confirmed_transaction === true) {
				
					this.$root.modal = {title: 'Perfect !', text: "NFT staked successfully !"};
					this.$root.$emit('openModal');
				}
				else {
					
					this.$root.modal = {title: 'Oops !', text: "Transaction did not confirm in time, please check the transaction "+signature+" and try again if necessary"};
					this.$root.$emit('openModal');
				}
				
			
				await this.get_staking_state();
				
			}
			catch(e) {
				
				console.log(e);
				this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
				this.$root.$emit('openModal');
			}
			
			this.$root.$emit('closeLoader');
		},
		
		stake_all: async function() {
		
			var signatures, confirmed_transaction, all_confirmed;
			
			var project = this.project;
			var nfts = this.not_staked;
			
			this.$root.$emit('openLoader');
			
			try {
				
				signatures = await stake_all(getSolanaObject(), getWalletAddress(), nfts, project.token_address, project.id);
				
				all_confirmed = true;
				
				for(var signature of signatures) {
				
					confirmed_transaction = await confirmTransaction(signature);
					
					if(confirmed_transaction === false)
						all_confirmed = false;
				}
				
				if(signatures.length == 0) {
					
					this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
					this.$root.$emit('openModal');
				}
				else {
				
					if(all_confirmed === true) {
					
						this.$root.modal = {title: 'Perfect !', text: "NFTs staked successfully !"};
						this.$root.$emit('openModal');
					}
					else {
						
						this.$root.modal = {title: 'Oops !', text: "At least one transaction did not confirm in time, please refresh the page and try again if necessary"};
						this.$root.$emit('openModal');
					}
				}
				
				await this.get_staking_state();
				
			}
			catch(e) {
				
				console.log(e);
				this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
				this.$root.$emit('openModal');
			}
			
			this.$root.$emit('closeLoader');
			
			
		},
		
		
		
		claim: async function(nft) {
			
			var signature, confirmed_transaction;
			
			var project = this.project;
			
			this.$root.$emit('openLoader');
			
			try {
				
				signature = await claim(getSolanaObject(), getWalletAddress(), nft.escrow, nft.escrow_staking, nft.nft_address, project.token_address, nft.collection_id, project.id);
				
				confirmed_transaction = await confirmTransaction(signature);
				
				if(confirmed_transaction === true) {
				
					this.$root.modal = {title: 'Perfect !', text: "Rawards claimed successfully !"};
					this.$root.$emit('openModal');
				}
				else {
					
					this.$root.modal = {title: 'Oops !', text: "Transaction did not confirm in time, please check the transaction "+signature+" and try again if necessary"};
					this.$root.$emit('openModal');
				}
				
				await this.get_staking_state();
				
			}
			catch(e) {
				
				console.log(e);
				this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
				this.$root.$emit('openModal');
			}
			
			this.$root.$emit('closeLoader');
			
			
		},
		
		claim_all: async function() {
		
			var signatures, confirmed_transaction, all_confirmed;
			
			var project = this.project;
			var nfts = this.staked;
			
			this.$root.$emit('openLoader');
			
			try {
				
				signatures = await claim_all(getSolanaObject(), getWalletAddress(), nfts, project.token_address, project.id);
				
				all_confirmed = true;
				
				for(var signature of signatures) {
				
					confirmed_transaction = await confirmTransaction(signature);
					
					if(confirmed_transaction === false)
						all_confirmed = false;
				}
				
				if(signatures.length == 0) {
					
					this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
					this.$root.$emit('openModal');
				}
				else {
				
					if(all_confirmed === true) {
					
						this.$root.modal = {title: 'Perfect !', text: "Rawards claimed successfully !"};
						this.$root.$emit('openModal');
					}
					else {
						
						this.$root.modal = {title: 'Oops !', text: "At least one transaction did not confirm in time, please refresh the page and try again if necessary"};
						this.$root.$emit('openModal');
					}
				}
				
				await this.get_staking_state();
				
			}
			catch(e) {
				
				console.log(e);
				this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
				this.$root.$emit('openModal');
			}
			
			this.$root.$emit('closeLoader');
			
			
		},
		
		unstake: async function(nft) {
			
			var signature, confirmed_transaction;
			
			var project = this.project;
			
			this.$root.$emit('openLoader');
			
			try {
				
				signature = await unstake(getSolanaObject(), getWalletAddress(), nft.escrow, nft.escrow_staking, nft.nft_address, project.token_address, nft.collection_id, project.id);
				
				confirmed_transaction = await confirmTransaction(signature);
				
				if(confirmed_transaction === true) {
				
					this.$root.modal = {title: 'Perfect !', text: "Rawards claimed successfully & nft unstaked successfully !"};
					this.$root.$emit('openModal');
				}
				else {
					
					this.$root.modal = {title: 'Oops !', text: "Transaction did not confirm in time, please check the transaction "+signature+" and try again if necessary"};
					this.$root.$emit('openModal');
				}
				
				await this.get_staking_state();
				
			}
			catch(e) {
				
				console.log(e);
				this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
				this.$root.$emit('openModal');
			}
			
			this.$root.$emit('closeLoader');
			
			
		},
		
		unstake_all: async function() {
		
			var signatures, confirmed_transaction, all_confirmed;
			
			var project = this.project;
			var nfts = this.staked;
			
			this.$root.$emit('openLoader');
			
			try {
				
				signatures = await unstake_all(getSolanaObject(), getWalletAddress(), nfts, project.token_address, project.id);
				
				all_confirmed = true;
				
				for(var signature of signatures) {
				
					confirmed_transaction = await confirmTransaction(signature);
					
					if(confirmed_transaction === false)
						all_confirmed = false;
				}
				
				if(signatures.length == 0) {
					
					this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
					this.$root.$emit('openModal');
				}
				else {
				
					if(all_confirmed === true) {
					
						this.$root.modal = {title: 'Perfect !', text: "Rawards claimed successfully & all nfts unstaked successfully !"};
						this.$root.$emit('openModal');
					}
					else {
						
						this.$root.modal = {title: 'Oops !', text: "At least one transaction did not confirm in time, please refresh the page and try again if necessary"};
						this.$root.$emit('openModal');
					}
				}
				
				await this.get_staking_state();
				
			}
			catch(e) {
				
				console.log(e);
				this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
				this.$root.$emit('openModal');
			}
			
			this.$root.$emit('closeLoader');
		},
		
		
	
		
		
	},
	watch:{}
	}
</script>



